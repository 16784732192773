<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light" [containerInside]="false">

    <!-- Navbar brand -->
    <mdb-navbar-brand class="w-50"><a class="navbar-brand" href="#"><img class="img-fluid w-50" src="/assets/img/logosite.png" alt="logo"></a></mdb-navbar-brand>
  
    <!-- Collapsible content -->
    <links>
  
      <!-- Links -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link waves-light" href="#acerca" mdbWavesEffect>Nuestro equipo</a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" href="#servicios" mdbWavesEffect>Servicios</a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" href="#descargas" mdbWavesEffect>Consultas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" href="#contacto" mdbWavesEffect>Contáctenos</a>
        </li>
      </ul>
      <!-- Links -->
    </links>
    <!-- Collapsible content -->
  
  </mdb-navbar>
  <!--/.Navbar-->