<!-- Footer -->
<footer class="page-footer font-small bg-blue darken-3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 py-2">
                <div class="mb-5 flex-center">
                    <!-- Facebook -->
                    <a class="fb-ic flex-center" href="https://www.facebook.com/terapiaencasaco" target="_blank">
                        <mdb-icon fab icon="facebook" size="2x" class="white-text px-3"></mdb-icon>
                    </a>
                    <!--Instagram-->
                    <a class="ins-ic" href="https://www.instagram.com/terapiaencasaco/" target="_blank">
                        <mdb-icon fab icon="instagram" size="2x" class="white-text px-3"></mdb-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2021 Copyright
        <a href="https://luzparasantiago.com/"> Terapia en casa</a>
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->