<!-- Default form contact -->
<form
  class="text-center border border-light p-5"
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
>
  <!-- Name -->
  <input
    type="text"
    formControlName="name"
    id="defaultContactFormName"
    mdbInput
    class="form-control mb-4"
    placeholder="Nombre"
  />
  <!-- Email -->
  <input
    type="email"
    formControlName="email"
    id="defaultContactFormEmail"
    mdbInput
    class="form-control mb-4"
    placeholder="Correo"
  />
  <!-- Message -->
  <div class="form-group">
    <textarea
      formControlName="message"
      class="form-control rounded-0"
      mdbInput
      id="exampleFormControlTextarea2"
      rows="3"
      placeholder="Mensaje"
    ></textarea>
  </div>
  <!-- Send button -->
  <button
    mdbBtn
    color="info"
    outline="true"
    block="true"
    class="z-depth-0 my-4 waves-effect"
    mdbWavesEffect
    type="submit"
    [disabled]="disabledSubmitButton"
  >
    Enviar
  </button>
</form>
<!-- Default form contact -->
