import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  images = ['/assets/img/slideswebsite01.jpeg', '/assets/img/slideswebsite02.jpeg']
  constructor() { }

  ngOnInit(): void {
  }

}
