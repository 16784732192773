<div class="container-fluid px-0">
  <div class="container">
    <div class="row py-5" id="acerca">
      <div class="col-md-5 col-12 col-lg-5">
        <img
          src="/assets/img/terapeutarespiratoria.jpeg"
          class="img-fluid"
          alt="perfil"
        />
      </div>
      <div class="col-md-7 col-12 col-lg-7">
        <p class="text-justify px-2">
          Terapeuta Respiratoria Independiente, habilitada por la Secretaria
          Distrital de Salud con REPS 1100137230.<br /><br />
          Más de 15 años de experiencia profesional. Estudios Superiores en
          Terapia Respiratoria Pediátrica. Experiencia en unidad de cuidados
          intensivos neonatal, pediátrica, adulto, cardiovascular y coronaria<br /><br />
          Certificada en BLS y ACLS por la fundación SALAMANDRA, según los
          lineamientos de la American Heart Association. Experiencia como
          docente en Unidad de Cuidado Intensivo Neonatal en HUCSR. Experiencia
          en paciente domiciliario.
        </p>
        <img
          src="/assets/img/logosentidades.jpeg"
          class="img-fluid"
          alt="entidades"
        />
      </div>
    </div>
  </div>
  <div class="container-fluid bg-cream px-0 py-4">
    <p class="h3-responsive py-3 title_seccion2 text-center" id="servicios">
      TERAPIAS RESPIRATORIAS
    </p>
    <div class="container">
      <div class="row py-3">
        <div class="col-md-4 col-12 col-lg-4">
          <img src="/assets/img/servicios01.png" class="img-fluid" alt="" />
          <p class="text-justify px-2">
            Con Administración de Medicamentos tales como: Broncodilatadores y
            Corticoesteroides, con el propósito de permeabilizar la vía aérea y
            mejorar la entrada y salida del aire.
          </p>
        </div>
        <div class="col-md-4 col-12 col-lg-4">
          <img src="/assets/img/servicios02.png" class="img-fluid" alt="" />
          <p class="text-justify px-2">
            Las cuales ayudan a desprender las secreciones que se encuentran
            adheridas al pulmón mejorando la respiración del paciente.
          </p>
        </div>
        <div class="col-md-4 col-12 col-lg-4">
          <img src="/assets/img/servicios03.png" class="img-fluid" alt="" />
          <p class="text-justify px-2">
            Los cuales ayudan a fortalecer y rehabilitar los músculos
            respiratorios que han sido afectados por diferentes patologías
            respiratorias crónicas o agudas.
          </p>
        </div>
      </div>

      <div class="row py-3">
        <div class="col-md-2 col-12 col-lg-2"></div>
        <div class="col-md-4 col-12 col-lg-4">
          <img src="/assets/img/servicios04.png" class="img-fluid" alt="" />
          <p class="text-justify px-2">
            Atención de pacientes con diagnostico de Asma, que necesiten
            realización de esquema de crisis (tres micronebulizaciones) con
            Broncodilatador.
          </p>
        </div>
        <div class="col-md-4 col-12 col-lg-4">
          <img src="/assets/img/servicios05.png" class="img-fluid" alt="" />
          <p class="text-justify px-2">
            Terapias especializadas con el propósito de permeabilizar la vía
            aérea del paciente, evitando la obstrucción por acumulo de
            secreciones.
          </p>
        </div>
        <div class="col-md-2 col-12 col-lg-2"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row text-center py-3" id="descargas">
      <div class="col-md-6 col-12 col-lg-6">
        <div class="card-servicio py-3">
          <img
            src="/assets/img/serviciosdomicilio1.png"
            class="img-fluid w-70"
            alt=""
          />
          <a href="https://wa.me/573202716356" target="_blank">
            <img
              src="/assets/img/serviciosdomicilio_boton.png"
              class="img-fluid w-50"
              alt=""
          /></a>
        </div>
      </div>
      <div class="col-md-6 col-12 col-lg-6">
        <div class="card-servicio py-3">
          <img
            src="/assets/img/nuestrosservicios_1.png"
            class="img-fluid w-70"
            alt=""
          />
          <a
            href="https://tueme.co/consultorio/consultorio-1-bambu-en-bogota-chapinero-133"
            target="_blank"
            ><img
              src="/assets/img/botonverinstalaciones.png"
              class="img-fluid w-50"
              alt=""
          /></a>
          <br />
          <a href="https://wa.me/573202716356" target="_blank"
            ><img
              src="/assets/img/botonagendarcita.png"
              class="img-fluid w-50"
              alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid" id="contacto">
    <div class="row">
      <div class="col-md-6 col-12 col-lg-6 px-0">
        <a href="https://wa.me/573202716356" target="_blank"
          ><img
            src="/assets/img/mapaterapiaencasa.jpeg"
            class="img-fluid"
            alt=""
        /></a>
      </div>
      <div class="col-md-6 col-12 col-lg-6 px-0">
        <app-contact></app-contact>
      </div>
    </div>
  </div>
</div>
