import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*', 
    'Access-Control-Allow-Headers': '*',
  })
};

@Injectable({
  providedIn: 'root'
})

export class MessageService {

  
  constructor(private _http: HttpClient) {}

  rootURL = 'http://localhost:3080/api';

  sendMessage(body): Observable<any> {
    return this._http.post(this.rootURL + '/sendmail', body, httpOptions);
  }
}