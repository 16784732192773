<ngb-carousel *ngIf="images">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <a href="https://wa.me/573202716356" target="_blank"><img [src]="images[0]" alt="Random second slide"></a>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <a href="https://wa.me/573202716356" target="_blank"><img [src]="images[1]" alt="Random third slide"></a>
      </div>
    </ng-template>
  </ngb-carousel>